const Map = () => {
    return (
        <iframe
            title="this is title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184532.2350043258!2d-80.07135497814389!3d43.72475835706376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b15eaa5d05abf%3A0x352d31667cc38677!2sBrampton%2C%20ON!5e0!3m2!1sen!2sca!4v1714613904329!5m2!1sen!2sca"
        ></iframe>
    );
};

export default Map;
