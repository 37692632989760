import PackageInfo from "../../components/package-info";
import PackageData from "../../data/global/packages.json";

const PackagesContainer = () => {
    return (
        <div className="contact-area">
            <div className="container">
                <div className="row">
                    <div
                        className="col-md-10 col-lg-8 m-auto text-center"
                        data-aos="fade-up"
                    >
                        <h2 className="title">
                            Packages
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-xl-10 m-auto">
                        <div className="contact-info-content">
                            {PackageData &&
                                PackageData.map((single, key) => {
                                    return (
                                        <PackageInfo data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackagesContainer;
